/**
 * Controla la configuración de la aplicación
 */

export const settings = {

  // firebase config

  firebase: {
    config: {
      apiKey: "AIzaSyAKysvWAm7eemM-0qitcgcl2hLSn9FJeBE",
      authDomain: "gestor-documental-67434.firebaseapp.com",
      databaseURL: "https://gestor-documental-67434.firebaseio.com",
      projectId: "gestor-documental-67434",
      storageBucket: "gestor-documental-67434.appspot.com",
      messagingSenderId: "4378599887",
      appId: "1:4378599887:web:4dc78950ac8060732cf2ba"
    }
  },

  // react-redux-firebase config

  reactReduxFirebase: {
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true
    }
  }
  
}